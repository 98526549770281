.wraper {
  width: 1230px;
  margin: 1px auto;
}
.pageNext {
  margin-bottom: 5px;
  overflow: hidden;
  line-height: 30px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.divtext {
  width: 90px;
  text-overflow: ellipsis;
  /*对超出容器的部分强制截取，高度不确定则换行*/
  overflow: hidden;
  /*显示省略符号来代表被修剪的文本。*/
  white-space: nowrap;
  height: 20px;
  text-align: center;
  line-height: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.dircss2 {
  border: 1px solid #5cadff;
  width: 90px;
  height: 80px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 18px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.dircss2:hover {
  cursor: pointer;
  border: 1px solid #409EFF;
  z-index: 1;
}
.dircss2:hover .picsrch {
  right: 3px;
  top: 1px;
  position: absolute;
  display: inline;
}
.dircss2:hover .fileset {
  left: 3px;
  top: 1px;
  position: absolute;
  display: inline;
}
.dircss {
  border: 1px solid #ccc;
  width: 90px;
  height: 80px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 18px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.dircss:hover {
  cursor: pointer;
  border: 1px solid #409EFF;
  z-index: 1;
}
.dircss:hover .picsrch {
  right: 3px;
  top: 1px;
  position: absolute;
  display: inline;
}
.dircss:hover .fileset {
  left: 3px;
  top: 1px;
  position: absolute;
  display: inline;
}
.picsrch {
  right: 3px;
  top: 1px;
  position: absolute;
  display: none;
}
.fileset {
  left: 3px;
  top: 1px;
  position: absolute;
  display: none;
}
.selected {
  border-color: red;
}
.selected:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  border-left: 2px solid red;
  border-top: 30px solid red;
  border-right: 30px solid transparent;
}
.selected:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 8px;
  background: transparent;
  top: 4px;
  left: 1px;
  border: 2px solid white;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-55deg);
  -ms-transform: rotate(-55deg);
  transform: rotate(-55deg);
  z-index: 8;
}
